import React, { useEffect, useState } from 'react';
import Home from './Home/Home';
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import PaymentForm from './Payment/PaymentForm';
import EventHome from './Events/EventHome';
import Settings from './Settings/Settings';
import EventPageSingle from './Events/EventPageSingle';
import EventMemberList from './EventMembers/EventMemberList';
import EventEdit from './Events/EventEdit';
import PaymentCreate from './Payment/PaymentCreate';
import ShowAllPayment from './Payment/ShowAllPayment';
import LoginPage from "./Login/LoginPage";
import AuthenticationChecker from "./Authentication/AuthenticationChecker";
import EventMembersSelector from "./EventMembers/EventMembersSelector";
import EventJoin from './Events/EventJoin';
import EventMemberCreate from './EventMembers/EventMemberCreate';
import PaymentSinglePage from './Payment/PaymentSinglePage';
import EventMemberEdit from './EventMembers/EventMemberEdit';
import Welcome from './Home/Welcome';
import Faq from './Home/Faq';
import PaymentAdjustmentCreate from './Payment/PaymentAdjustmentCreate';
import PrivacyPolicy from './Home/PrivacyPolicy';
import FirstBlog from './Home/DeveloperBlog/FirstBlog';
import BlogList from './Home/DeveloperBlog/BlogList';
import SecondBlog from './Home/DeveloperBlog/SecondBlog';
import ThirdBlog from './Home/DeveloperBlog/ThirdBlog';
import LogoutHandler from './Logout/LogoutHandler';

const App = () => {

  return(
    <Router>
      <Routes>
        <Route path="/" element={<Welcome/>}/>
        <Route path="/faq" element={<Faq/>}/>
        <Route path="/privacy" element={<PrivacyPolicy/>}/>
        <Route path="/blogs/first-blog" element={<FirstBlog/>}/>
        <Route path="/blogs/basic-concept-of-how-split-app-simplifies-payments" element={<SecondBlog/>}/>
        <Route path="/blogs/the-power-of-events" element={<ThirdBlog/>}/>
        <Route path="/blogs" element={<BlogList/>}/>
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/logout" element={<LogoutHandler/>}/>
        <Route path="/new" element={<LoginPage/>}/>        
        <Route path="/home" element= {
          <AuthenticationChecker>
            <Home/>
          </AuthenticationChecker>
        }/>
        {/* <Route path="/experimentHome" element= {
            <ExperimentHome/>
        }/> */}
        <Route path="/payment" element= {
          <AuthenticationChecker>
            <PaymentForm/>
          </AuthenticationChecker>
        } />
        <Route path="/payment" element= {
          <AuthenticationChecker>
            <PaymentForm/>
          </AuthenticationChecker>
        } />
        <Route path="/paymentAdjustment" element= {
          <AuthenticationChecker>
            <PaymentAdjustmentCreate/>
          </AuthenticationChecker>
        } />
        <Route path="/payment/showAll/:eventid" element= {
          <AuthenticationChecker>
            <ShowAllPayment/>
          </AuthenticationChecker>
        } />
        <Route path="/payment/:action/id/:id" element= {
          <AuthenticationChecker>
            <PaymentSinglePage/>
          </AuthenticationChecker>
        } />
        <Route path="/events" element= {
          <AuthenticationChecker>
            <EventHome/>
          </AuthenticationChecker>
        } />
        {/* <Route path="/api/*" element= {
          <AuthenticationChecker>
            <Home/>
          </AuthenticationChecker>
        } /> */}
        <Route path="/events/id/:id" element= {
          <AuthenticationChecker>
            <EventPageSingle/>
          </AuthenticationChecker>
        } />
        <Route path="/events/:id" element= {
          <AuthenticationChecker>
            <EventEdit/>
          </AuthenticationChecker>
        } />
        <Route path="/events/members/id/:id" element= {
          <AuthenticationChecker>
            <EventMemberList/>
          </AuthenticationChecker>
        } />
        <Route path="/events/members/edit" element= {
          <AuthenticationChecker>
            <EventMemberEdit/>
          </AuthenticationChecker>
        } />
        <Route path="/settings" element= {
          <AuthenticationChecker>
            <Settings/>
          </AuthenticationChecker>
        } />
        <Route path="/events/id/:id/payment/:action" element= {
          <AuthenticationChecker>
           <PaymentCreate/>
          </AuthenticationChecker>
        } />
        <Route path="/payment/event/id/:id/:action" element= {
          <AuthenticationChecker>
            <PaymentCreate/>
          </AuthenticationChecker>
        } />
        <Route path="/payment/paidEventMembersSelect" element= {
          <AuthenticationChecker>
            <EventMembersSelector/>
          </AuthenticationChecker>
        } />
        <Route path="/event/join/:id" element= {<EventJoin/>} />
        <Route path="/events/members/add" element= {
          <AuthenticationChecker>
            <EventMemberCreate/>
          </AuthenticationChecker>
        } />
      </Routes>
 
    </Router>
  )
}

export default App;