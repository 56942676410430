import React, { useState } from 'react';
import './MobileNavigationMenuCSS.css';
import { Briefcase, Home, LogOut, Settings } from 'react-feather';

const MobileNavigationMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="menu-container">
      <input
        type="checkbox"
        id="menu-toggle"
        className="menu-toggle"
        checked={isOpen}
        onChange={toggleMenu}
      />
      <label htmlFor="menu-toggle" className={`menu-icon ${isOpen ? 'open' : ''}`}>
        <span className="menu-icon-line"></span>
        <span className="menu-icon-line"></span>
        <span className="menu-icon-line"></span>
      </label>
      <div className={`menu${isOpen ? ' open' : ''}`}>
        <ul className="menu-items">
          <li><a href="/home"><Home  size={16}/><span className='menu-link-text'>Home</span></a></li>
          <li><a href="/events"><Briefcase size={16}/><span className='menu-link-text'>Events</span></a></li>
          <li><a href="/"><Settings size={16}/><span className='menu-link-text'>Settings</span></a></li>
          <li><a href="/logout"><LogOut size={16}/><span className='menu-link-text'>Logout</span></a></li>

          {/* Add more list items as needed */}
        </ul>
      </div>
    </div>
  );
};

export default MobileNavigationMenu;
