import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import useAPIService from "../APIHandler/APIService"

const LogoutHandler = () =>
{
    const nav = useNavigate()
    const api = useAPIService()

    useEffect(() => {
        api.handleSignout()
      }, []);

  return (
   <div></div>
  );

}

export default LogoutHandler