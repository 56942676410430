import axios from 'axios';
import {useNavigate} from "react-router-dom";

const api = axios.create({
    // baseURL: 'http://localhost:3000',
});

function useAPIService() {
    const navigate = useNavigate();
    const getSingleEventByID = async (id:string) => {
        const uri = `/api/events/id/${id}`;
        const response = await doGet(uri);
        if(response === "Unauthorized") {
            navigate('/login');
        }
        return response;
    }

    const handleSignout = async () => {
        const response = await api.get('/signout');
        if (response.status === 200) {
            navigate('/login');
        }
    }

    const getEventMembers = async (eventId:string) => {
        const uri = `/api/events/id/${eventId}/members`;
        const response = await doGet(uri);
        if(response === "Unauthorized") {
            navigate('/login');
        }
        return response;
    }

    const redirectToLoginPage = () => {
        navigate("/login");
    }

    const getByURL = async (uri:string) => {
        const response = await doGet(uri);
        if(response === "Unauthorized") {
            navigate('/login');
        }
        return response;
    }

    return {getSingleEventByID,redirectToLoginPage,getEventMembers,getByURL, handleSignout};
}

async function doGet(uri:string) {
    try {
        const response = await api.get(uri);
        if(response.status === 401) {
            const redirectURL = response.data;
            window.location.href = redirectURL;
        }
        return response.data;
    } catch(exception:any) {
        if(exception.response.status === 401) {
            return "Unauthorized";
        }
    }
}

export default useAPIService;