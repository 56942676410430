import HeaderBar from "../../NavBars/HeaderBar"
import BottomSection from "../BottomSection"

const BlogList = () => {
    return (
        <div>
            <HeaderBar isWelcome={true}/>
            <div>
            <section className="middle-section">
                <h1 className="with-border">Blog List</h1>
                <h3><a href="/blogs/first-blog">First Blog</a></h3>
                    <p className="feature-description with-bottom-margin">
                     Posted on 27th, Decemeber 2023
                    </p>
                <h3><a href="/blogs/basic-concept-of-how-split-app-simplifies-payments">Basic concept of how Split App simplifies payments</a></h3>
                <p className="feature-description with-bottom-margin">
                    Posted on 28th, Decemeber 2023
                </p>
                <h3><a href="/blogs/the-power-of-events">Unlocking the Magic of Split App: Embracing the Power of Events</a></h3>
                <p className="feature-description with-bottom-margin">
                    Posted on 29th, Decemeber 2023
                </p>
            </section>
        </div>
            <BottomSection/>
        </div>
    )
}

export default BlogList;